<template>
  <div class="home">
    <div class="tilte_btn">
      <span class="lineY"></span>
      <span>
        <h1>职位数据库</h1>
      </span>
      <span>
        <el-button @click="getJobAdd" type="primary" class="el_btn" size="medium">新增职位</el-button>
        <el-button @click="batchReviewOpenDialog" type="danger" icon="el-icon-share" class="el_btn" size="medium">批量审核</el-button>
      </span>
      <!--<span>
               <el-button type="primary" class="el_btn" size="medium" @click="batchWriteJobs">批量录入职位</el-button>
           </span>-->
    </div>
    <nav class="nav_sech" style="justify-content: space-between;">
      <div>
        <el-input style="width:250px;margin-right:15px" placeholder="请输入职位名称/关键字" v-model="searchParam.keywords" size="medium"></el-input>
        <el-select class="el_input" size="medium" v-if="userType == 0" v-model="searchParam.jobtype" placeholder="职位类型" clearable>
          <el-option v-for="item in jobtypeadd" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker class="el_input" size="medium" v-model="searchParam.publishTime" value-format="yyyy-MM-dd" type="date" placeholder="发布时间">
        </el-date-picker>
        <el-select class="el_input" size="medium" v-model="searchParam.recruitType" placeholder="招聘类型" clearable>
          <el-option label="全部" value="-1"></el-option>
          <el-option label="暂停招聘" value="0"></el-option>
          <el-option label="招聘中" value="1"></el-option>
        </el-select>
        <el-select class="el_input" size="medium" v-model="searchParam.audit" placeholder="审核状态" style="margin-top:8px;" clearable>
          <el-option label="全部" value="-1"></el-option>
          <el-option label="待审核" value="0"></el-option>
          <el-option label="审核失败" value="1"></el-option>
          <el-option label="审核成功" value="2"></el-option>
          <el-option label="再次提交" value="3"></el-option>
        </el-select>
        <el-button @click="searchList" type="primary" size="medium" class="el_btn">查询</el-button>
      </div>
    </nav>
    <div class="inte"></div>
    <main class="el_table" style="margin: 15px 15px 0">
      <el-table :data="tableData" style="width: 100%;margin-bottom: 20px" height="55vh" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="jobname" label="职位名称" align="center" line-height="60" width="320">
        </el-table-column>
        <el-table-column prop="fabulousNum" label="浏览量" align="center" line-height="60" width="100">
        </el-table-column>
        <el-table-column prop="jobApplyNumber" label="投递量" align="center" line-height="60" width="100">
        </el-table-column>
        <el-table-column label="审核状态" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.audit==-1">全部</span>
            <span style="color:blue" v-if="scope.row.audit==0">待审核</span>
            <span style="color:red" v-if="scope.row.audit==1">审核失败</span>
            <span style="color:green" v-if="scope.row.audit==2">审核成功</span>
            <span style="color:purple" v-if="scope.row.audit==3">再次提交</span>
          </template>
        </el-table-column>
        <el-table-column label="职位类型" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.audit==-1">全部</span>
            <span style="color:blue" v-if="scope.row.jobtype==1">全职</span>
            <span style="color:deepskyblue" v-if="scope.row.jobtype==2">普工/兼职</span>
          </template>
        </el-table-column>
        <el-table-column label="招聘地区" align="center" width="170">
          <template slot-scope="scope">
            <span>{{scope.row.wprovinceName}}</span>
            <span>-</span>
            <span>{{scope.row.wcityName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="indtypeNames" label="职位类型" align="center" width="170">
        </el-table-column>
        <el-table-column prop="publishTime" label="发布时间" align="center" width="150">
        </el-table-column>
        <el-table-column prop="entname" label="公司名称" align="center" width="280">
        </el-table-column>
        <el-table-column prop="recruitType" label="招聘类型" align="center" width="170">
          <template slot-scope="scope">
            <span>{{scope.row.recruitType==1?'招聘中':'暂停招聘'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="284">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="handleDetail(scope.$index, scope.row)">查看详情</el-button>
            <el-button type="danger" size="small" @click="deleteJob(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height:60px;text-align: center;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10,20, 30, 40, 50]" :page-size="10" :current-page="pages"
          layout="total, sizes, prev, pager, next, jumper" :total="connt">
        </el-pagination>
      </div>
    </main>

    <el-dialog title="批量录入职位数据" :visible.sync="batchDataDialogVisible">
      <div>岗位数据导入模板 <a href="http://119.23.207.186:7203/job_temp.xlsx" style="color:#409EFF;text-decoration: none">下载模板</a></div>
      <div class="import-data">
        <el-upload class="upload-demo" drag :action="batchJobUrl" :show-file-list="false" :on-success="dataUploadSuccess" :on-error="dataUploadError">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将人员数据文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">请先下载模版，填写后上传。文件大小不超过500kb</div>
          <div class="el-upload__tip" slot="tip">
            <a href="#">下载错误文件</a>
          </div>
        </el-upload>
        <!-- <div class="el-upload__tip" style="width: 8%" slot="tip" v-if="importRes.importing">正在导入</div>
                    <div class="el-upload__tip import-logs" slot="tip"  v-if="importRes.importing"> -->
        <!-- <div v-for="(item, index) in importRes.infos"><span v-html="item"></span><br></div> -->
        <!-- </div> -->

        <!-- </div> -->
        <div style="text-align:center">
          <!-- <el-button type="primary" @click="batchMemberImport">导 入</el-button> -->
          <el-button @click="batchDataDialogVisible = false">取 消</el-button>
        </div>
        <!-- <div style="margin-top: 20px" v-html="importMsg"></div> -->
      </div>
    </el-dialog>

    <!--批量审核弹出框-->
    <el-dialog title="职位批量审核" :visible.sync="batchAuditDialogVisible" width="50%">
      <template>
        <div class="audit-content">
          <div class="main-audit">
            <div class="audit-label">审核操作：</div>
            <div class="audit-c">
              <el-radio-group v-model="batchAuditData.audit">
                <el-radio :label="2">审核通过</el-radio>
                <el-radio :label="1">审核不通过</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="main-audit">
            <div class="audit-label">审核意见：</div>
            <div class="audit-c">
              <el-input type="textarea" :rows="4" placeholder="请输入审核意见" v-model="batchAuditData.auditmsg">
              </el-input>

            </div>
          </div>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchAuditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchAuditDialogOkClick">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Jobs",
  data () {
    return {
      queryform: {},
      keywords: '',
      select: '',
      tableData: [],
      searchParam: {
        jobtype: '',
        publishTime: '',
        recruitType: '',
        keywords: '',
        audit: ''
      },
      userType: 0,
      jobtypeadd: [],
      batchJobUrl: this.API.ajob.batchJobUrl,
      pages: 1,
      limits: 10,
      connt: null,//分页操作

      batchDataDialogVisible: false,
      batchAuditDialogVisible: false,
      multipleSelection: [],
      batchAuditData: {
        ids: "",
        audit: 0,
        auditmsg: ""
      },
      auditStatus: ["待审核", "<b style='color: red'>审核不通过</b>", "<b style='color: darkgreen'>审核通过</b>", "再次提交"],
    }
  },
  created () {
    this.userType = this.Util.getUserType();
    if (this.userType == 1) {
      this.searchParam.jobtype = 2;
    }
    this.getTableData();
    this.jobtypeadd = this.Util.jobtypeAdd;
  },
  methods: {
    handleSelectionChange (val) {
      console.log(val);
      this.multipleSelection = val;
    },
    //查询
    searchJobList: function () {
      this.pages = 1;
      this.getTableData();
    },
    searchList: function () {
      this.pages = 1;
      this.getTableData();
    },
    batchWriteJobs: function () {
      this.$message.error("没做")
      //this.batchDataDialogVisible = true;
    },
    //获取职位列表数据
    getTableData: function () {
      var _self = this;
      var publishTimeStart = '', publishTimeEnd = '';
      if (this.searchParam.publishTime) {
        publishTimeStart = this.searchParam.publishTime;
        publishTimeEnd = this.searchParam.publishTime;
      }
      axios({
        url: _self.API.ajob.list,
        headers: {
          token: _self.Util.getjwtToken(),
        },
        params: {
          publishTimeStart: publishTimeStart,
          publishTimeEnd: publishTimeEnd,
          jobtype: this.searchParam.jobtype,
          recruitType: this.searchParam.recruitType,
          keywords: this.searchParam.keywords,
          audit: this.searchParam.audit,
          page: _self.pages,
          limit: _self.limits
        }
      }).then(function (e) {
        var resp = e.data;
        if (resp.success) {
          _self.tableData = resp.data.list;
          _self.connt = resp.data.pager.recordCount;
        } else {
          _self.tableDat = [];
          _self.connt = null
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    batchReviewOpenDialog: function () {
      if (this.multipleSelection.length <= 0) {
        this.$message.error('请至少选择一行再操作');
        return;
      }
      this.batchAuditDialogVisible = true;
    },
    //批量审核处理
    batchAuditDialogOkClick: function () {
      var _self = this;
      var msg = _self.auditStatus[_self.batchAuditData.audit];
      _self.$confirm('确定要将选择的职位全部' + msg + '吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '保存中，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        var json = [];
        for (var i = 0; i < _self.multipleSelection.length; i++) {
          //if(_self.multipleSelection[i].audit>0) {
          json.push("'" + _self.multipleSelection[i].id + "'");
          //}
        }
        _self.batchAuditData.ids = json.join(",");
        axios({
          url: _self.API.ajob.batchAudit,
          method: "POST",
          headers: {
            token: _self.Util.getjwtToken(),
          },
          data: _self.batchAuditData
        }).then(function (e) {
          loading.close();
          var res = e.data;
          if (res.success) {
            _self.$message.success(res.msg);
            _self.batchAuditDialogVisible = false;
            _self.getTableData(); //重新获取列表
          } else {
            _self.$message.error(res.msg);
          }
        }).catch(function (error) {
          loading.close();
        })
      })
    },
    //详情页
    handleDetail: function (index, row) {
      this.$parent.pullItem("职位信息", "JobDetail", { id: row.id });
    },
    deleteJob: function(index, row){
      let _this = this;
      this.$confirm('确认删除该职位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.delete(_this.API.ajob.deleteJob,{
          headers:{
            token:_this.Util.getjwtToken(),
          },
          params:{
            id:row.id
          }
        }).then(function (res) {
          if(res.data.success){
            _this.$message({
              type: "success",
              message: "删除成功！"
            });
            _this.getTableData();
          }

        }).catch(function (error) {
          console.log(error);
        });
      });
    },
    //添加职位
    getJobAdd: function () {
      this.$parent.pullItem("添加职位", "JobAdd");
    },
    //分页操作
    handleSizeChange: function (e) {
      this.limits = e;
      this.getTableData()
    },
    handleCurrentChange: function (e) {
      this.pages = e;
      this.getTableData()
    },
    dataUploadSuccess: function (e) {
      // console.log('dataUploadSuccess', e);
    },
    dataUploadError: function (e) {
      // console.log('dataUploadError', e);
    }
  }

}
</script>

<style scoped lang="scss">
.home {
  background: #fff;
}
.input-with-select {
  border: none !important;
}
.audit-content {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  align-items: center;

  .main-info {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: center;
    .left-content {
      width: 24%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
    .mid-content {
      width: 38%;
    }
    .right-content {
      width: 38%;
    }

    .line-info {
      width: 100%;
      height: 30px;
      display: flex;
      flex-flow: row nowrap;

      .info-label {
        width: 25%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }
      .info-value {
        width: 75%;
        padding-left: 10px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
    }
  }
  .main-hobby {
    width: 100%;
    min-height: 50px;
    display: flex;
    flex-flow: row wrap;
    padding: 5px;
  }

  .main-audit {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .audit-label {
      width: 10%;
    }
    .audit-c {
      width: 70%;
    }
  }
}
</style>